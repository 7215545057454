import {AUTHORIZE} from "../constants/LoginConstants";
import {noLoginOrPasswordAuth, beginLogin} from "../actions/LoginActions"

export function emptyLoginPassword({ dispatch }) {
  return function(next) {
    return function(action) {
      if (action.type === AUTHORIZE) {	
	    let login = action.payload.login
		let password = action.payload.password
		if (!login || !password){
	  		return dispatch(noLoginOrPasswordAuth(!Boolean(login), !Boolean(password))) 
		}else{
			return dispatch(beginLogin(login, password))
		}
      }
      return next(action);
    };
  };
}



