import React from 'react'
import Avatar from "@material-ui/core/Avatar"
import base64url from "base64url";

class PsnAvatars extends React.Component{
    constructor(props) {
        super(props)
        let tenant = localStorage.getItem('tenant')
        let avatarsUrl = window.AVATARS_URL
        let {email, displayName} = props
        let link = `${avatarsUrl}${tenant}/${base64url(email)}/${base64url(displayName)}`
        this.state = {avatarUrl: link}
    }

    render(){
        return <Avatar className="userAva" src={this.state.avatarUrl}> </Avatar>
    }
}


export default PsnAvatars
