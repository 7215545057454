import { SHOW_ALERT, HIDE_ALERT } from "../constants/AlertsConstants";

export const showAlertAction = (message, alertType) => {
  return (dispatch) => {
    dispatch(hidingAlertAction());
    dispatch({
      type: SHOW_ALERT,
      payload: { message, alertType },
    });
  };
};

export const hidingAlertAction = () => {
  return (dispatch) => {
    delay(3000).then(() => {
      dispatch(hideAlertAction());
    });
  };
};

export const hideAlertAction = () => ({
  type: HIDE_ALERT,
});

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
